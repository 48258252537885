import React from "react"
import { Helmet } from "react-helmet"

import Header from "src/components/headers/header"
import Footer from "src/components/footers/footer"
import Form from "src/components/formulaires/form-contact"


export default function index() {

  return (
    <div className="bg-white">
      <Helmet>
        <title>Demande de contact</title>
        <meta name="description" content="Contact Thérapies de couple" />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header>

      <main className="relative">
        <div className="relative bg-white">
          <div className="relative max-w-7xl mx-auto py-8 px-4">
            
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
              Contact Philippe Lamy : Thérapeute de la relation (au travail, dans le couple), sexothérapeute
              </span>
            </h1>
            <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
            Vous souhaitez être renseigné sur les thérapies.
            <br />
            Remplissez le formulaire ci-après pour toute demande de renseignement sur les séances.
            </p>

          </div>
        </div>

        <Form />

      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  )
}